import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ChevronLeft, ChevronRight } from "lucide-react";
import "./style.css";

const CarouselButton = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Ensure all paths start with forward slash
  const pages = ["/about", "/services", "/contact", "/"];

  const handleNext = () => {
    // Clean up the current path by removing any double slashes
    const currentPath = location.pathname.replace(/\/+/g, "/");
    const currentIndex = pages.indexOf(currentPath);

    // If path not found in pages array, default to first page
    if (currentIndex === -1) {
      navigate(pages[0]);
      return;
    }

    const nextIndex = (currentIndex + 1) % pages.length;
    navigate(pages[nextIndex]);
  };

  const handlePrevious = () => {
    // Clean up the current path by removing any double slashes
    const currentPath = location.pathname.replace(/\/+/g, "/");
    const currentIndex = pages.indexOf(currentPath);

    // If path not found in pages array, default to last page
    if (currentIndex === -1) {
      navigate(pages[pages.length - 1]);
      return;
    }

    const previousIndex = (currentIndex - 1 + pages.length) % pages.length;
    navigate(pages[previousIndex]);
  };

  return (
    <div className="carousel-nav">
      <button
        className="carousel-button carousel-button-left"
        onClick={handlePrevious}
        aria-label="Previous page"
      >
        <ChevronLeft size={36} />
      </button>

      <button
        className="carousel-button carousel-button-right"
        onClick={handleNext}
        aria-label="Next page"
      >
        <ChevronRight size={36} />
      </button>
    </div>
  );
};

export default CarouselButton;
