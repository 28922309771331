import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Views/Home";
import Services from "./Views/Services";
import Navbar from "./components/Navbar";
import Contact from "./Views/Contact";
import About from "./Views/About";
// import CareerPage from "./Views/Career";
// import JobDetailsPage from "./Views/Career/JobDetails";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="services" element={<Services />} />
          <Route path="contact" element={<Contact />} />
          <Route path="about" element={<About />} />
          {/* <Route path="career" element={<CareerPage />} /> */}
          {/* <Route path="career/:jobId" element={<JobDetailsPage />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
