import React from "react";
import CarouselButton from "../../components/Button/CarouselButton";
import "./style.css";

const About = () => {
  return (
    <div className="about-wrapper">
      <div className="carousel-button-div">
        <CarouselButton />
      </div>

      <div className="about-hero">
        <div className="hero-overlay"></div>
        <h1 className="hero-title">
          Crafting Next-Generation
          <br />
          Software Experiences for a Dynamic World
        </h1>
      </div>

      <main className="about-content">
        <h2 className="about-title">About Behelam</h2>

        <div className="content-section">
          <p className="content-text">
            At Behelam, we are a leading technology solutions provider dedicated
            to helping businesses succeed in today’s digital world. With a focus
            on innovation and excellence, we offer a comprehensive range of
            products and services tailored to meet the evolving needs of
            businesses across various industries. Our expertise spans across Web
            Development, Mobile App Development, Enterprise Software Solutions,
            and Contract Staffing, ensuring that our clients receive the right
            technology and talent to achieve their business goals efficiently.
            Whether you're looking for custom software, scalable IT solutions,
            or skilled professionals to support your projects, Behelam is your
            trusted partner.
          </p>

          <h3 className="section-heading">Our Services</h3>
          <p className="content-text">
            We specialize in web development, creating responsive and
            high-performance websites to enhance your online presence. Our
            mobile app development services focus on designing user-friendly iOS
            and Android applications that effectively engage your audience.
            Additionally, we offer enterprise software solutions, providing
            custom software tailored to optimize business operations and boost
            productivity. For businesses seeking flexibility, our contract
            staffing services deliver skilled professionals to support your
            projects on both short-term and long-term bases.
          </p>
          <h3 className="section-heading">Why Choose Behelam?</h3>
          <p className="content-text">
            At Behelam, we understand your business and provide tailored
            solutions to fit your specific needs. We utilize cutting-edge
            technology, staying ahead with the latest tools and frameworks to
            deliver future-ready solutions. Our experienced team of
            professionals brings expertise across various domains to ensure
            exceptional outcomes. We offer end-to-end support, from consultation
            and development to deployment and ongoing assistance. Our mission is
            to empower businesses with reliable technology solutions and
            outstanding service.
          </p>
          <footer className="content-footer">
            <p className="footer-text">
              Partner with Behelam and experience the power of bespoke
              development and contract staffing services that drive your
              business growth and success. Together, let's transform your vision
              into reality.
            </p>
          </footer>
        </div>
      </main>
    </div>
  );
};

export default About;
